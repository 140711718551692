import {Button} from "antd";
import React, {FormEvent, useState} from "react";
import {getHttpClient} from "../../service/API";
import {SalesCodeDropDownMenu} from "../orderGridFilters/SalesCodeSelector";
import {
  retrieveReallocatableSalesCodes,
  SalesCodeType,
} from "../../service/SalesCodeService";
import {
  getGlobalState,
  setGlobalState,
  useGlobalState,
} from "../../state/GlobalState";
import {BackendSelectionStatus, FormattedOrder} from "../../types/order";
import {ApiUrls} from "../../enums/ApiUrls";
import ReactTooltip from "react-tooltip";
import {TextTranslation} from "../../utils/Translations";

export function ReallocateButton({vin}: {vin: string}) {
  const [salesCode] = useGlobalState("salesCode");
  const [reallocateModalHidden, setReallocateModalHidden] = useState(true);
  const [newSalesCode, setNewSalesCode] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [orderGridData] = useGlobalState("orderGridData");
  const [reallocatableSalesCodes, setReallocatableSalesCodes] = useState<
    SalesCodeType[]
  >([]);
  const [offeringWindowOngoing] = useGlobalState("offeringWindowOngoing");
  const [reallocationPeriodOngoing] = useGlobalState(
    "reallocationPeriodOngoing"
  );

  function isAbleToBeReallocated(): boolean {
    let vehicleSelection = getVehicleData()!.selection;
    return (
      vehicleSelection === BackendSelectionStatus.Rejected ||
      (!offeringWindowOngoing &&
        reallocationPeriodOngoing &&
        (vehicleSelection === BackendSelectionStatus.NO_ACTION ||
          vehicleSelection === BackendSelectionStatus.Reallocated))
    );
  }

  function submitReallocation(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (newSalesCode !== "") {
      setReallocateModalHidden(true);
      sendReallocationRequest();
    }
  }

  function sendReallocationRequest() {
    getHttpClient()
      .post(ApiUrls.reallocate, {
        salesCode: newSalesCode,
        vin: vin,
      })
      .then((response) => {
        if (response.status === 200) {
          setNewSalesCode("");
          setGlobalState("submissionMade", !getGlobalState("submissionMade"));
        }
      });
  }

  function reallocateDropDownEventHandler() {
    return (event: any) => {
      setNewSalesCode(event.value);
      setSubmitButtonDisabled(false);
    };
  }

  function getVehicleData() {
    return orderGridData.find((order: FormattedOrder) => {
      return order.vin === vin;
    });
  }

  function prepAndDisplayModalOnClick() {
    setReallocateModalHidden(false);
    retrieveReallocatableSalesCodes(salesCode).then(
      (salesCodes: SalesCodeType[]) => {
        setReallocatableSalesCodes(salesCodes);
      }
    );
  }

  return (
    <div data-testid={"reallocate component"}>
      <div
        hidden={reallocateModalHidden}
        className={
          "confirmation-modal fmc-dialog fds-align--center" +
          (reallocateModalHidden ? "" : " fmc-dialog--opened")
        }
        aria-label={"reallocation"}
        role={"dialog"}>
        <div className={"fmc-dialog__content"}>
          <button
            onClick={() => {
              setReallocateModalHidden(true);
            }}
            aria-label={"close"}
            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
            id="close-reallocate-modal"
          />
          <div></div>
          <h1 style={{fontSize: "1.5em"}}>
            <TextTranslation textId={"ReallocatingLabel"} /> {vin}
          </h1>
          <form
            onSubmit={(e) => {
              submitReallocation(e);
            }}>
            <label htmlFor="salesCodesOptions">
              <TextTranslation textId={"EnterSalesCodeLabel"} />
            </label>
            <SalesCodeDropDownMenu
              handleChange={reallocateDropDownEventHandler()}
              salesCode={newSalesCode}
              salesCodeOptions={reallocatableSalesCodes}
            />
            <div className="fmc-mt-6">
              <button
                aria-label={"submit reallocation"}
                className={"fmc-button  fmc-mb-3"}
                style={{zIndex: 0}}
                disabled={submitButtonDisabled}>
                <TextTranslation textId={"submitReallocationLabel"} />
              </button>
            </div>
          </form>
        </div>
      </div>

      <ReactTooltip
        role={"tooltip"}
        place={"bottom"}
        effect={"solid"}
        multiline={true}
        id={vin}
        aria-label={"testingTesting"}
        disable={isAbleToBeReallocated()}>
        <TextTranslation textId={"vehiclesMustBeRejected"} />
      </ReactTooltip>
      <span
        data-tip={"data-tip-to-override"}
        data-for={vin}
        aria-label={"reallocate button"}>
        <Button
          aria-label={"reallocate"}
          className={"fmc-button"}
          onClick={prepAndDisplayModalOnClick}
          disabled={!isAbleToBeReallocated()}
          data-testid={"reallocate-button"}>
          <TextTranslation textId={"reallocateLabel"} />
        </Button>
      </span>
    </div>
  );
}
