import {OrderHistoryBySalesCode} from "../../components/orderHistory/OrderHistoryBySalesCode";
import {useGlobalState} from "../../state/GlobalState";
import {OrderHistoryByRegion} from "../../components/orderHistory/OrderHistoryByRegion";
import React from "react";
import {getSkin, Skin} from "../../service/SkinService";
import {OrderHistoryList} from "../../components/orderHistory/OrderHistoryList";
import {TextTranslation} from "../../utils/Translations";

export function OrderHistory() {
  const [hasReadAllPermissions] = useGlobalState("hasReadAllPermissions");

  function showOrderHistory() {
    if (getSkin() === Skin.MEW) {
      return <OrderHistoryList />;
    } else if (hasReadAllPermissions) {
      return <OrderHistoryByRegion />;
    } else {
      return <OrderHistoryBySalesCode />;
    }
  }

  return (
    <div>
      <div className={"portal-header-history"}>
        <label className="portal-name">
          <TextTranslation textId={"headerLabel"} />
        </label>
      </div>

      <h3 className={"page-header"}>
        <TextTranslation textId={"orderHistoryPageSubject"} />
      </h3>

      <p className={"page-subject"}>
        <TextTranslation textId={"orderHistoryPageDescription"} />
      </p>

      {showOrderHistory()}
    </div>
  );
}
