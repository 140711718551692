import {atom} from "recoil";

type ConfigurationSelection = {
  configurationId: string;
  rowKey: string;
  amount: number;
  selected: boolean;
  requested: boolean;
  vehicleLine: string;
};

const selectionState = atom<ConfigurationSelection[]>({
  key: "selectionState",
  default: [],
});

export {selectionState};
export type {ConfigurationSelection};
