import {TextTranslation} from "../../utils/Translations";

export function ModelEFAQ() {
  return (
    <div className={"content-width"}>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Question1Label"} />
        </span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Answer1Label"} />
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Question2Label"} />
        </span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Answer2Label"} />
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Question3Label"} />
        </span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Answer3Label"} />
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Question4Label"} />
        </span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Answer4Label"} />
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Question5Label"} />
        </span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Answer5Label"} />
        </span>
      </div>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Question6Label"} />
        </span>
      </p>
      <div className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation textId={"Answer6Label"} />
        </span>
      </div>
    </div>
  );
}
