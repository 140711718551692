import "../../styles/lincoln/lincolnAllocationMethodology.scss";
import {TextTranslation} from "../../utils/Translations";

export function LincolnAllocationMethodology() {
  return (
    <div className={"lincoln-allocation-methodology"}>
      <p className={"lincoln-allocation-header"}>
        <TextTranslation textId={"allocationMethodologyLabel"} />
      </p>
      <div>
        <span className={"orange-line"}></span>
        <span className={"lincoln-allocation-text"}>
          <TextTranslation textId={"allocationMethodology"} />
        </span>
      </div>
    </div>
  );
}
