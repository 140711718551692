import {Table} from "antd";
import {TableColumn} from "../../models/TableColumn";
import React, {useEffect} from "react";
import {useGlobalState} from "../../state/GlobalState";
import {AddToSelectionButton} from "./AddToSelectionButton";
import {AmountRequestedDropDown} from "./AmountRequestedDropDown";
import {useRecoilState} from "recoil";
import {
  ConfigurationSelection,
  selectionState,
} from "../../state/SelectionState";
import {
  ConfigurationGridData,
  getRowKey,
  getTopBarFeaturesWithMsrp,
} from "../../types/ConfigurationGridData";
import {Filters} from "./Filters";
import {createMSRPHeader} from "./stockingGuide/StockingGuideToolTip";
import {ModelEConfigurationStatus} from "../../enums/ModelEConfigurationStatus";
import {ConfigurationFeature} from "../../models/Configuration";
import {
  getAllDescriptionsByVehicleLineAndCountry,
  searchDescriptionModelE,
} from "../../service/ContentService";
import styles from "../../styles/ConfigurationGrid.module.scss";
import Star from "../../images/incentive-star.svg";
import {Optionality} from "../../enums/Optionality";

export function ConfigurationGrid(props: {
  configurationData: ConfigurationGridData[];
  btg: number;
}) {
  const [vehicleLine] = useGlobalState("vehicleLine");
  const [selections, setSelections] = useRecoilState(selectionState);
  const [selectedFilterDropDownOptions] = useGlobalState(
    "filterDropDownOptions"
  );
  const [selectedFilterRangeOptions] = useGlobalState("filterRangeOptions");
  const [wersDescriptions] = useGlobalState("wersDescriptions");

  useEffect(() => {
    if (vehicleLine !== null && vehicleLine !== "")
      getAllDescriptionsByVehicleLineAndCountry("2024 " + vehicleLine, "US");
  }, [vehicleLine]);

  function isOnHold(selection: ModelEConfigurationStatus) {
    return selection === ModelEConfigurationStatus.ON_HOLD;
  }

  function exceededBtg() {
    let totalAmount = 0;
    for (let selection of selections.filter(
      (it) => it.vehicleLine === vehicleLine && !it.requested
    )) {
      totalAmount += selection.amount;
    }
    return totalAmount > props.btg;
  }

  function handleDropdownChange(rowKey: string, amount?: number) {
    updateConfigurationSelection(rowKey, false, amount);
  }

  function selectRow(rowKey: string) {
    updateConfigurationSelection(rowKey, true);
  }

  function updateConfigurationSelection(
    rowKey: string,
    selected: boolean,
    amount?: number
  ) {
    const newSelections: ConfigurationSelection[] = selections.map((it) => {
      if (it.rowKey === rowKey) {
        return {
          configurationId: it.configurationId,
          rowKey: it.rowKey,
          amount: amount !== undefined ? amount : it.amount,
          selected: selected,
          requested: it.requested,
          vehicleLine: it.vehicleLine,
        };
      }
      return it;
    });
    setSelections(newSelections);
  }

  useEffect(() => {
    setSelections(
      props.configurationData.map((configuration) => {
        return {
          configurationId: configuration.configurationId,
          rowKey: getRowKey(configuration),
          amount:
            configuration.selection === ModelEConfigurationStatus.ON_HOLD
              ? configuration.count
              : 0,
          selected: false,
          requested:
            configuration.selection === ModelEConfigurationStatus.ON_HOLD,
          vehicleLine: configuration.vehicleLine,
        };
      })
    );
  }, [props.configurationData, setSelections]);

  function applyFilter(
    gridContents: ConfigurationGridData[],
    field: string,
    filterValue: string | [number, number] | null | undefined
  ) {
    if (!filterValue) {
      return gridContents;
    }
    return gridContents.filter((configuration) => {
      const fieldValue = configuration[field as keyof ConfigurationGridData];
      if (Array.isArray(fieldValue)) {
        return fieldValue.some(
          (option) =>
            option.description === filterValue &&
            option.optionality === Optionality.OPTIONAL
        );
      } else if (
        typeof fieldValue === "object" &&
        fieldValue !== null &&
        "description" in fieldValue &&
        !Array.isArray(fieldValue)
      ) {
        return fieldValue.description === filterValue;
      } else if (
        typeof fieldValue === "object" &&
        "totalMSRPWithDestinationDelivery" in fieldValue
      ) {
        return (
          filterValue[0] <= fieldValue.totalMSRPWithDestinationDelivery &&
          fieldValue.totalMSRPWithDestinationDelivery <= filterValue[1]
        );
      } else {
        return fieldValue === filterValue;
      }
    });
  }

  function getFilteredGridDataIfVehicleLineSelectedOtherwiseShowNothing() {
    if (!vehicleLine) {
      return [];
    }
    return getFilteredConfigurationGridData();
  }

  function getFilteredConfigurationGridData() {
    let filteredGridContents = props.configurationData;

    filteredGridContents = applyFilter(
      filteredGridContents,
      "vehicleLine",
      vehicleLine
    );
    Filters.forEach((filter) => {
      filteredGridContents = applyFilter(
        filteredGridContents,
        filter,
        selectedFilterDropDownOptions.get(filter)?.value
      );
    });
    filteredGridContents = applyFilter(
      filteredGridContents,
      "msrpWithDetails",
      selectedFilterRangeOptions.get("msrp")?.value
    );

    return filteredGridContents;
  }

  const codeDescriptions = [
    {
      code: "311A",
      description: "XLT (311A)",
    },
    {
      code: "312A",
      description: "Flash (312A)",
    },
    {
      code: "511A",
      description: "Lariat (511A)",
    },
    {
      code: "710A",
      description: "Platinum (710A)",
    },
  ];

  function getCodeDescription(code: string) {
    const codeDescription = codeDescriptions.find((it) => it.code === code);

    if (codeDescription) return codeDescription.description;
    else return code;
  }

  const formatPackages = (
    feature: ConfigurationFeature,
    index: number
  ): React.JSX.Element | null => {
    if (
      feature.optionality === "OPTIONAL" ||
      feature.optionality === "AVAILABLE"
    ) {
      return (
        <li key={index}>
          {searchDescriptionModelE("en_US", feature, wersDescriptions)}
        </li>
      );
    } else {
      return null;
    }
  };

  function formatMoney(money: number): string {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(money);
  }

  function buildPackagesList(data: ConfigurationGridData) {
    const configurationFeaturesPackages = data.packages.map(formatPackages);
    if (
      configurationFeaturesPackages.filter(
        (description) => description !== null
      ).length > 0
    ) {
      return <ul>{configurationFeaturesPackages}</ul>;
    }
    return <ul>N/A</ul>;
  }

  function buildGridColumns(): TableColumn[] {
    let renderMSRPWithDetails = (data: ConfigurationGridData) => (
      <div className={styles.msrp}>
        <div className={styles.msrpTotal}>
          <p>Total:</p>
          <p>
            {formatMoney(data.msrpWithDetails.totalMSRPWithDestinationDelivery)}
          </p>
        </div>
        <div className={styles.msrpDetail}>
          <p>Base:</p>
          <p>{formatMoney(data.msrpWithDetails.baseMSRP)}</p>
        </div>
        <div className={styles.msrpDetail}>
          <p>Options:</p>
          <p>{formatMoney(data.msrpWithDetails.onlyOptionsMSRP)}</p>
        </div>
        <div className={styles.msrpDetail}>
          <p>D&D:</p>
          <p>{formatMoney(data.msrpWithDetails.destinationDeliveryPrice)}</p>
        </div>
      </div>
    );
    let tableColumns = [
      new TableColumn({
        title: "*",
        key: "incentive",
        render: (data: ConfigurationGridData) => {
          return data.isIncentivized ? (
            <img
              style={{height: "20px", width: "20px"}}
              src={Star}
              alt={"Incentive Program"}
            />
          ) : (
            <p></p>
          );
        },
      }),
      new TableColumn({
        title: "Model Year",
        key: "modelYear",
        render: (data: ConfigurationGridData) => <>{data.modelYear}</>,
      }),
      new TableColumn({
        title: "Code",
        key: "code",
        render: (data: ConfigurationGridData) => (
          <>{getCodeDescription(data.code.description)}</>
        ),
      }),
      new TableColumn({
        title: "Battery",
        key: "battery",
        render: (data: ConfigurationGridData) => (
          <>{searchDescriptionModelE("en_US", data.engine, wersDescriptions)}</>
        ),
      }),
      new TableColumn({
        title: "Paint",
        key: "paint",
        render: (data: ConfigurationGridData) => (
          <>{searchDescriptionModelE("en_US", data.paint, wersDescriptions)}</>
        ),
      }),
      new TableColumn({
        title: "Optional Packages",
        key: "packages",
        render: (data: ConfigurationGridData) => buildPackagesList(data),
      }),
      new TableColumn({
        title: "Optional Features",
        key: "options",
        render: (data: ConfigurationGridData) => (
          <ul>
            {data.options.map(formatPackages)}
            {data.dealerInstalledOptions.map(formatPackages)}
            {getTopBarFeaturesWithMsrp(data).map(formatPackages)}
          </ul>
        ),
      }),
      new TableColumn({
        title: () => createMSRPHeader("MSRP"),
        key: "mSRP",
        render: (data: ConfigurationGridData) => {
          return renderMSRPWithDetails(data);
        },
      }),
      new TableColumn({
        title: "Quantity",
        key: "quantity",
        render: (data: ConfigurationGridData) => (
          <div className={styles.quantityCell}>
            <p
              className={styles.btgExceededError}
              hidden={!exceededBtg()}
              data-testid="btg-exceeded-msg">
              BTG limit exceeded
            </p>
            <AmountRequestedDropDown
              limit={data.count}
              onChange={handleDropdownChange}
              configurationId={data.configurationId}
              rowKey={getRowKey(data)}
              disabled={isOnHold(data.selection)}
              selectedAmount={
                selections.filter((it) => it.rowKey === getRowKey(data)).pop()
                  ?.amount
              }
            />
          </div>
        ),
      }),

      new TableColumn({
        title: "Selections",
        key: "selections",
        render: (data: ConfigurationGridData) => (
          <AddToSelectionButton
            configurationId={data.configurationId}
            rowKey={getRowKey(data)}
            configurationStatus={data.selection}
            userSelected={Boolean(
              selections.filter((it) => it.rowKey === getRowKey(data)).pop()
                ?.selected
            )}
            disabled={exceededBtg() || isOnHold(data.selection)}
            onClick={selectRow}
          />
        ),
      }),
    ];

    return tableColumns;
  }

  return (
    <div data-testid="order-grid" className={styles.orderGrid}>
      <Table
        className="fmc-table"
        rowKey={(it) => getRowKey(it)}
        columns={buildGridColumns().filter((column) => column.title !== "")}
        dataSource={getFilteredGridDataIfVehicleLineSelectedOtherwiseShowNothing()}
        pagination={false}
      />
    </div>
  );
}
