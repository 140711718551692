import {TextTranslation} from "../../utils/Translations";

export function LincolnFAQ() {
  return (
    <div data-brand={"lincoln"}>
      <div className={"content-width"}>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Question1Label"} />
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Answer1Label"} />
          </span>
        </p>

        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Question2Label"} />
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Answer2Label"} />
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Question3Label"} />
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Answer3Label"} />
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Question4Label"} />
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Answer4Label"} />
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Question5Label"} />
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Answer5Label"} />
          </span>
        </p>
        <p className={"lincoln-question  lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Question6Label"} />
          </span>
        </p>
        <p className={"answer lincoln-common-faq-row"}>
          <span className={"lead-letter"}>
            <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
          </span>
          <span className={"faq-text"}>
            <TextTranslation textId={"Answer6Label"} />
          </span>
        </p>
      </div>
    </div>
  );
}
