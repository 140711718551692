import {getHttpClient} from "./API";

export interface StockingGuideData {
  salesCode: string;
  vehicleLine: string;
  stockTarget: number;
  demoAllowance: number;
  numberOfVehiclesOnLot: number;
  balanceToGo: number;
}

export async function getStockingGuide(
  salesCode: string,
  vehicleLine: string
): Promise<StockingGuideData> {
  const response = await getHttpClient().get(
    "/api/dealer/" + salesCode + "/stockingGuide/" + vehicleLine
  );
  return response.data;
}

export async function updateStockingGuide(
  salesCode: string,
  vehicleLine: string,
  stockTarget: number,
  demoAllowance: number
): Promise<void> {
  await getHttpClient().put(
    "/api/stockingGuide",
    {},
    {
      params: {
        salesCode: salesCode,
        vehicleLine: vehicleLine,
        stockTarget: stockTarget,
        demoAllowance: demoAllowance,
      },
    }
  );
}
