import isEmail from "validator/lib/isEmail";
import React from "react";
import {SessionStorageKeys} from "../../enums/SessionStorageKeys";
import {TextTranslation, useTranslation} from "../../utils/Translations";

interface SubmitModalProps {
  setModalVisibilityFalse: () => void;
  setUserInputEmail: (input: string | null) => void;
  userInputEmail: string | null;
  confirmSubmission: () => void;
}

export function SubmitModal(props: SubmitModalProps) {
  const jwtEmail = sessionStorage.getItem(SessionStorageKeys.EMAIL);

  const translations = useTranslation(["enterEmailHereLabel"]);
  return (
    <div
      className="confirmation-modal fmc-dialog fds-align--center fmc-dialog--opened"
      role="dialog"
      aria-modal="true"
      data-testid="submitConfirmationModal"
      aria-label="Confirm submission modal">
      <div className="fmc-dialog__content">
        <button
          onClick={props.setModalVisibilityFalse}
          data-testid={"close"}
          className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
          id="close-confirm-submission-modal"
        />
        <p className="fmc-type--body1 fmc-mb-3">
          <TextTranslation textId={"financialResponsibilityLabel"} />
        </p>
        <p className="fmc-type--body1 fmc-mb-3">
          {jwtEmail ? (
            <>
              <TextTranslation
                textId={"confirmationEmailWithDefaultEmailPart1"}
              />
              <b>{jwtEmail}</b>*
              <TextTranslation
                textId={"confirmationEmailWithDefaultEmailPart2"}
              />
            </>
          ) : (
            <>
              <TextTranslation textId={"confirmationRequestingEmail"} />
            </>
          )}
        </p>
        <input
          className="fmc-input"
          type="text"
          data-testid="email-input"
          placeholder={translations["enterEmailHereLabel"]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.setUserInputEmail(event.target.value);
          }}
        />
        <div className="user-profile-link-container">
          <p>
            <TextTranslation textId={"doNotRecognizeEmailLabel"} />{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={"https://www.dsps.ford.com/"}
              className="external-link">
              <TextTranslation textId={"userProfileLabel"} />
            </a>
            <TextTranslation textId={"emailWillBeUpdatedLabel"} />
          </p>
        </div>
        <p className="fmc-type--body1 fmc-mt-3 fds-style--italic fds-m--t-5">
          <TextTranslation textId={"submitModalNote"} />
        </p>
        <div className="fmc-mt-6 center">
          <button
            disabled={
              props.userInputEmail ? !isEmail(props.userInputEmail) : true
            }
            onClick={props.confirmSubmission}
            data-testid={"confirm"}
            className="fmc-button fmc-mb-3"
            aria-label="Approve order"
            id={"confirm-vehicleSubmission"}>
            <TextTranslation textId={"approveButtonLabel"} />
          </button>
        </div>
      </div>
    </div>
  );
}
