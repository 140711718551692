import React, {useEffect, useState} from "react";
import {getHttpClient} from "../../service/API";
import {ApiUrls} from "../../enums/ApiUrls";
import {getSkin, Skin} from "../../service/SkinService";
import {ContactUsInput} from "./ContactUsInput";
import {useRecoilState} from "recoil";
import ContactForm from "../../state/ContactForm";
import {TextTranslation} from "../../utils/Translations";

export function ContactUsModal(props: {
  isVisible: boolean;
  setVisibility: (visible: boolean) => void;
}) {
  const [cdsid, setCdsid] = useState("");
  const [salesCode, setSalesCode] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [issueMessage, setIssueMessage] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [showIssueAlert, setShowIssueAlert] = useState(false);
  const [, setContactUsVisibility] = useRecoilState(ContactForm);

  function shouldDisplayIssueAlert() {
    setShowIssueAlert(!issueMessage?.length);
  }

  function confirmSubmission(event: any) {
    event.preventDefault();
    getHttpClient()
      .post(ApiUrls.dealerIssue, {
        cdsid: cdsid,
        salesCode: salesCode,
        emailAddress: emailAddress,
        issueMessage: issueMessage,
        siteOrigin: getSkin(),
      })
      .then((response) => {
        if (response.status === 200) {
          props.setVisibility(false);
          setContactUsVisibility(true);
        } else {
          return <p>Submission failed due to an error, please try again.</p>;
        }
      });
  }

  useEffect(() => {
    setIsSubmitButtonDisabled(
      cdsid === "" || salesCode === "" || issueMessage === ""
    );
  }, [cdsid, salesCode, issueMessage]);

  return (
    <div
      className="confirmation-modal fmc-dialog fds-align--center fmc-dialog--opened"
      role="dialog"
      aria-modal="true"
      data-testid="confirmationModal"
      aria-label="Confirm submission modal">
      <div className="fmc-dialog__content">
        <button
          onClick={() => {
            props.setVisibility(false);
          }}
          data-testid={"close"}
          className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
          id="close-contact-modal"
        />
        <div
          className="fmc-type--body1 contact-us-font fmc-mb-3"
          data-testid={"contact-us-instructions"}>
          <TextTranslation textId={"contactUsHeader1"} />
          <br />
          <br />
          <TextTranslation textId={"contactUsHeader2"} />{" "}
          <span className={"red-asterisk"}>*</span>.
        </div>
        <form onSubmit={confirmSubmission}>
          <div className={"contact-us-inputs contact-us-font"}>
            <ContactUsInput
              missingInputAlertMessageText={
                <TextTranslation textId={"contactUsCDSIDWarning"} />
              }
              inputFieldTitle={
                <TextTranslation textId={"contactUsCDSIDInput"} />
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCdsid(event.target.value);
              }}
              inputFieldText={cdsid}
              className={"cdsid-input"}
            />
            <ContactUsInput
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSalesCode(event.target.value);
              }}
              inputFieldTitle={
                <TextTranslation textId={"contactUsSalesCode"} />
              }
              missingInputAlertMessageText={
                <TextTranslation textId={"contactUsSalesCodeWarning"} />
              }
              inputFieldText={salesCode}
              className={"sales-code-input"}
            />

            <div
              className={"email-address-input contact-us-input-margin"}
              data-testid={"email-address-input-contact-modal"}>
              <label>
                <TextTranslation textId={"contactUsEmail"} />
                <input
                  className="contact-us-input-margin contact-us-inputs contact-us-input-style"
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmailAddress(event.target.value);
                  }}
                />
              </label>
            </div>
            <div
              className={"description-input contact-us-input-margin"}
              data-testid={"enter-wslx-id"}>
              <label>
                <TextTranslation textId={"contactUsDescription"} />
                <span className={"red-asterisk"}>*</span>
                <textarea
                  className={`description-textarea ${
                    showIssueAlert && "contact-us-alert"
                  }`}
                  data-testid={"description-input"}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setIssueMessage(event.target.value);
                  }}
                  onBlur={shouldDisplayIssueAlert}
                  rows={4}
                />
              </label>
              {showIssueAlert && (
                <div
                  className={
                    getSkin() === Skin.LPIVW
                      ? "lincoln-contact-us-alert-text"
                      : "contact-us-alert-text"
                  }>
                  <span
                    className="fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
                    data-testid={`description-input-warning-message`}
                  />{" "}
                  <span>
                    <TextTranslation textId={"contactUsDescriptionWarning"} />
                  </span>
                </div>
              )}
            </div>

            <div className="fmc-mt-6 center-content">
              <button
                className="fmc-button fmc-mb-3"
                data-testid="confirm-contact-us"
                id="confirm-contact-submission"
                type="submit"
                disabled={isSubmitButtonDisabled}>
                <TextTranslation textId={"submitButtonLabel"} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
