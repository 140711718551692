import * as React from "react";

export class TableColumn {
  title?: string | ((data: any) => React.ReactNode) | React.ReactElement;
  key: string;
  dataIndex?: string;
  filters?: any[];
  onFilter?: any;
  filterSearch?: boolean;
  render?: (data: any) => React.ReactNode;

  constructor(input: {
    title: string | ((data: any) => React.ReactNode) | React.ReactElement;
    key: string;
    filters?: any[];
    onFilter?: any;
    filterSearch?: boolean;
    render?: (data: any) => React.ReactNode;
  }) {
    this.title = input.title;
    this.filters = input.filters;
    this.filterSearch = input.filterSearch;
    this.onFilter = input.onFilter;
    this.key = input.key;

    if (input.render) {
      this.render = input.render;
    } else {
      this.dataIndex = this.key;
    }
  }
}
