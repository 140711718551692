import "../../../styles/StockingGuide.scss";
import {useGlobalState} from "../../../state/GlobalState";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {selectionState} from "../../../state/SelectionState";
import {createStockingGuideHeader} from "./StockingGuideToolTip";
import {StockingGuideData} from "../../../service/StockingGuideService";

export function StockingGuide(props: {stockingGuide: StockingGuideData}) {
  const [vehicleLine] = useGlobalState("vehicleLine");

  const selectedVehicles = useRecoilValue(selectionState);
  const [selectedAmount, setSelectedAmount] = useState<number>(0);

  useEffect(() => {
    let totalSelection = 0;
    for (const selectionKey in selectedVehicles) {
      const selection = selectedVehicles[selectionKey];
      if (selection.selected && selection.vehicleLine === vehicleLine) {
        totalSelection += selection.amount;
      }
    }
    setSelectedAmount(totalSelection);
  }, [selectedVehicles, vehicleLine]);

  function getDisplayValue(value: number | undefined): string {
    return value === undefined ? "--" : value.toString();
  }

  const btgPreview = Math.max(
    0,
    props.stockingGuide.balanceToGo - selectedAmount
  );

  return (
    <>
      <div className={"stock-guide"}>
        <div className={"stock-guide-header"}>ALLOCATION</div>
        <div className={"stock-guide-column-container"}>
          <div className={"stock-guide-column"}>
            <p className={"stock-guide-column-header"}>VEHICLE LINE</p>
            <p
              className={"stock-guide-column-value"}
              data-testid="vehicle-line-value">
              {props.stockingGuide?.vehicleLine === "F-150"
                ? "Lightning"
                : props.stockingGuide?.vehicleLine}
            </p>
          </div>
          <div className={"vertical-line stock-guide-column"}></div>
          <div className={"stock-guide-column"}>
            {createStockingGuideHeader("stockTarget")}
            <p
              className={"stock-guide-column-value"}
              data-testid={"stock-target-number"}>
              {getDisplayValue(props.stockingGuide?.stockTarget)}
            </p>
          </div>
          <div className={"stock-guide-column"}>
            {createStockingGuideHeader("demoAllowance")}
            <p
              className={"stock-guide-column-value"}
              data-testid={"demo-allowance-number"}>
              {getDisplayValue(props.stockingGuide?.demoAllowance)}
            </p>
          </div>
          <div className={"stock-guide-column"}>
            {createStockingGuideHeader("totalAllowed")}
            <p
              className={"stock-guide-column-value"}
              data-testid={"total-allowed-number"}>
              {getDisplayValue(
                props.stockingGuide.stockTarget +
                  props.stockingGuide.demoAllowance
              )}
            </p>
          </div>
          <div className={"stock-guide-column"}>
            {createStockingGuideHeader("totalAvailable")}
            <p
              className={"stock-guide-column-value"}
              data-testid={"total-available-number"}>
              {getDisplayValue(props.stockingGuide?.numberOfVehiclesOnLot)}
            </p>
          </div>
          <div className={"stock-guide-column"}>
            {createStockingGuideHeader("stockBtg")}
            <p
              className={"stock-guide-column-value"}
              data-testid={"btg-number"}>
              {btgPreview}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
