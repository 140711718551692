import {getFirstName} from "../../service/AuthService";

import {TextTranslation} from "../../utils/Translations";

export function Greetings() {
  return (
    <div className={"greetings"}>
      <TextTranslation textId={"greetingText"}></TextTranslation>
      {getFirstName()}
    </div>
  );
}
