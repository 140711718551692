import {retrieveSalesCodesByCdsid} from "../../service/admin/AdminSalesCodesService";
import React, {useState} from "react";
import styles from "../../styles/AdminPage.module.scss";
import {TextTranslation, useTranslation} from "../../utils/Translations";

export function PermissionsAdminTool() {
  const [salesCodes, setSalesCodes] = useState<string[]>([]);
  const [cdsid, setCdsid] = useState<string>("");
  const [submittedCdsid, setSubmittedCdsid] = useState<string>("");
  const translations = useTranslation(["permissionCDSPlaceholder"]);

  function retrieveSalesCodes(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    retrieveSalesCodesByCdsid(cdsid).then((result) => {
      setSalesCodes(result);
      setSubmittedCdsid(cdsid);
    });
  }

  return (
    <>
      <h2 className={styles.permissionsHeader}>
        <TextTranslation textId={"permissionLookup"} />
      </h2>
      <form
        onSubmit={retrieveSalesCodes}
        className={styles.adminsPermissionsContainer}>
        <input
          placeholder={translations["permissionCDSPlaceholder"]}
          onChange={(event) => setCdsid(event.target.value)}
          className="fmc-input"
        />
        <button className="fmc-button">
          <TextTranslation textId={"searchButton"} />
        </button>
      </form>
      <div>
        {salesCodes && salesCodes.length > 0 && (
          <>
            <div>CDSID has read/submit access for these sales codes: </div>
            <br />
            <div className={styles.salesCodeContainer}>
              {salesCodes.map((salesCode) => (
                <span className={styles.salesCode} key={salesCode}>
                  {salesCode}
                </span>
              ))}
            </div>
          </>
        )}
        {salesCodes && salesCodes.length === 0 && submittedCdsid !== "" && (
          <span>No sales codes found for {submittedCdsid}.</span>
        )}
        {!salesCodes && submittedCdsid !== "" && (
          <span>Failed to Retrieve Sales Codes</span>
        )}
      </div>
    </>
  );
}
