import React, {useState} from "react";
import Modal from "react-modal";
import "../../styles/WarningMessage.scss";
import {useIsMounted} from "./useIsMounted";
import {getSkin, Skin} from "../../service/SkinService";
import {TextTranslation} from "../../utils/Translations";

export function AreYouSureButton(props: {
  confirmAction: () => Promise<void>;
  buttonLabel: string | React.ReactElement;
  className?: string;
  confirmationQuestion: React.ReactElement;
}) {
  const isMounted = useIsMounted();
  const [isVisible, setIsVisible] = useState(false);

  const closeModal = () => {
    if (isMounted()) {
      setIsVisible(false);
    }
  };

  const skin = getSkin();

  return (
    <>
      <Modal isOpen={isVisible} ariaHideApp={false} onRequestClose={closeModal}>
        <div>
          <button
            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear close-button"
            data-testid="close-warning-message-modal"
            aria-label={"Close warning modal"}
            onClick={closeModal}></button>
          <br />
        </div>
        <div
          data-brand={skin === Skin.LPIVW ? "lincoln" : "ford"}
          className={
            skin === Skin.LPIVW
              ? "lpivw-font-family__proxima-nova confirmation-message-container"
              : "confirmation-message-container"
          }>
          {props.confirmationQuestion}
          <div>
            <button
              className="fmc-button confirmation-button"
              onClick={() => {
                props
                  .confirmAction()
                  .then(closeModal)
                  .catch(() => {});
              }}>
              <TextTranslation textId={"confirmationButton"} />
            </button>
            <button
              className="fmc-button fmc-button--outlined"
              onClick={closeModal}>
              <TextTranslation textId={"cancelButton"} />
            </button>
          </div>
        </div>
      </Modal>
      <button
        onClick={() => {
          setIsVisible(true);
        }}
        className={props.className}>
        {props.buttonLabel}
      </button>
    </>
  );
}
