import home from "../../images/home.svg";
import * as React from "react";
import {TextTranslation} from "../../utils/Translations";

export function HomeLink() {
  return (
    <a className={"white-font"} href={"/"} aria-label={"link-to-home"}>
      <TextTranslation textId={"homeText"} />
      <img className={"home-logo"} src={home} alt={"Home"} />
    </a>
  );
}
