import {useState} from "react";
import Modal from "react-modal";
import {
  getCvwDisclaimerAccepted,
  putCvwDisclaimer,
} from "../../service/UserService";
import {TextTranslation} from "../../utils/Translations";

export function CvwDisclaimer() {
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [shouldShowCvwDisclaimer, setShouldShowCvwDisclaimer] = useState(false);

  let [isOpen, setIsOpen] = useState(true);

  useState(() => {
    getCvwDisclaimerAccepted().then((response) => {
      if (response.data === false) {
        setShouldShowCvwDisclaimer(true);
      }
    });
  });

  return (
    <>
      {shouldShowCvwDisclaimer && (
        <Modal isOpen={isOpen} ariaHideApp={false}>
          <h3 className={"modal-header"}>
            <TextTranslation textId={"title"} />
          </h3>
          <h3 className={"modal-header"}>
            <TextTranslation textId={"allocationMethodologyNoticeTitle"} />
          </h3>
          <div className={"modal-description"}>
            <TextTranslation textId={"modalDescriptionPartOne"} />
            <br />
            <TextTranslation textId={"modalDescriptionPartTwo"} />
          </div>
          <div className="fmc-mt-6 center-content">
            <input
              className={"fmc-mr-2"}
              onChange={() => {
                setIsCheckboxDisabled(!isCheckboxDisabled);
              }}
              type={"checkbox"}
            />
            <TextTranslation textId={"disclaimerAcceptanceLabel"} />
          </div>
          <div className="fmc-mt-6 center-content">
            <button
              className={"fmc-button fmc-mb-3"}
              disabled={isCheckboxDisabled}
              onClick={() => {
                putCvwDisclaimer().then(() => {
                  setIsOpen(false);
                });
              }}>
              <TextTranslation textId={"continueLabel"} />
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
