import React, {useEffect, useState} from "react";
import "../../styles/Home.scss";
import {getSalesCodesByBrand} from "../../service/SalesCodeService";
import {getSkin, Skin} from "../../service/SkinService";
import {ModelEContainer} from "../../brandContainers/modelE/ModelEContainer";
import {RRContainer} from "../../brandContainers/rr/RRContainer";
import {LincolnContainer} from "../../brandContainers/lincoln/LincolnContainer";
import {getBrand} from "../../service/BrandService";
import {TextTranslation} from "../../utils/Translations";

function PortalBody(props: {skin: Skin}) {
  switch (props.skin) {
    case Skin.LPIVW:
      return <LincolnContainer />;
    case Skin.MEW:
      return <ModelEContainer />;
    default:
      return <RRContainer />;
  }
}

export function Home() {
  const [userHasReadPermissions, setUserHasReadPermissions] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const skin = getSkin();

  useEffect(() => {
    let salesCodes = getSalesCodesByBrand(getBrand(skin));

    salesCodes
      .then((salesCodes) => setUserHasReadPermissions(salesCodes.length > 0))
      .finally(() => setIsLoading(false));
  }, [skin]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && !userHasReadPermissions && <Permissions />}
      {!isLoading && userHasReadPermissions && <PortalBody skin={skin} />}
    </>
  );
}

function Permissions() {
  return (
    <>
      <section className="permissions-container">
        <strong className="permissions-text">
          <TextTranslation textId={"permissionsErrorMessage"} />
        </strong>
        <div className="permissions-instruction">
          <div className="permissions-contact">
            <TextTranslation textId={"contactDealerAdmin"} />
          </div>
          <div className="title-and-tooltip">
            <TextTranslation textId={"securityAssistance"} />
          </div>
        </div>
      </section>
    </>
  );
}

function LoadingSpinner() {
  return (
    <div
      className="fmc-activity-circle loading-indicator-position"
      data-testid="loading-indicator">
      <div className="fmc-activity-circle__progress"></div>
    </div>
  );
}
