import "../../../styles/Home.scss";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {getIcon, IconName} from "../../../service/iconFinderService";
import {TextTranslation} from "../../../utils/Translations";

export function OrderHistoryMenuButton() {
  const navigation = useNavigate();

  return (
    <>
      <MenuItem
        data-testid={"orderHistoryMenuButton"}
        onClick={() => {
          navigation("/history");
        }}>
        <ListItemIcon>
          <img
            src={getIcon(IconName.OrderHistory)}
            className="menu-button-icons"
            alt={"order history menu button"}
          />
        </ListItemIcon>
        <ListItemText>
          <TextTranslation textId={"orderHistoryMenuButton"} />
        </ListItemText>
      </MenuItem>
    </>
  );
}
