import ReactTooltip from "react-tooltip";
import "../../styles/RebillingTooltip.scss";
import InfoToolTip from "../../images/infoTooltip.png";
import {TextTranslation} from "../../utils/Translations";
import ReactDOMServer from "react-dom/server";

export function RRTooltip(props: {contentId: string; image: boolean}) {
  function getImg() {
    if (props.image) {
      return (
        <img className={"tool-tip-img"} src={InfoToolTip} alt={"InfoToolTip"} />
      );
    } else return null;
  }

  return (
    <>
      {
        <div className={"tool-tip"} data-testid={"rr-tooltip"}>
          <span
            className={"tool-tip"}
            data-testid={"tool-tip-span"}
            data-tip={ReactDOMServer.renderToStaticMarkup(
              <TextTranslation textId={props.contentId} />
            )}
            data-for={"rr-tooltip"}>
            {getImg()}
          </span>
          <ReactTooltip
            role={"rr-tooltip"}
            place={"bottom"}
            effect={"solid"}
            multiline={true}
            id={"rr-tooltip"}
            class={"react-tooltip"}></ReactTooltip>
        </div>
      }
    </>
  );
}
