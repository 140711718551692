import "../../styles/faq.scss";
import {FordFAQ} from "../../components/faq/FordFAQ";
import {getSkin, Skin} from "../../service/SkinService";
import * as React from "react";
import {LincolnFAQ} from "../../components/faq/LincolnFAQ";
import {ModelEFAQ} from "../../components/faq/ModelEFAQ";
import {TextTranslation} from "../../utils/Translations";

export function FAQ() {
  function getFAQ() {
    if (getSkin() === Skin.LPIVW) {
      return <LincolnFAQ />;
    } else if (getSkin() === Skin.MEW) {
      return <ModelEFAQ />;
    } else {
      return <FordFAQ />;
    }
  }

  return (
    <div id={"faq"}>
      <h3 className={"page-header"}>
        <TextTranslation textId={"FAQTitleLabel"} />
      </h3>
      {getFAQ()}
    </div>
  );
}
