import Modal from "react-modal";
import {useState} from "react";
import {getHttpClient} from "../../service/API";
import {ApiUrls} from "../../enums/ApiUrls";
import {getSkin, Skin} from "../../service/SkinService";
import {TextTranslation, useTranslation} from "../../utils/Translations";

export function WarningMessageModal(props: {
  isVisible: boolean;
  setVisibility: (visible: boolean) => void;
}) {
  let warningModalIsOpen = props.isVisible;
  let setWarningModalIsOpen = props.setVisibility;
  let [warningMessage, setWarningMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const skin = getSkin();
  const translations = useTranslation(["warningMessagePlaceholder"]);

  function getHeader() {
    return (
      <>
        <div data-brand={skin === Skin.LPIVW ? "lincoln" : "ford"}>
          <button
            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear close-button"
            data-testid="close-warning-message-modal"
            aria-label={"Close warning modal"}
            onClick={() => {
              setWarningModalIsOpen(false);
            }}></button>
        </div>

        <h3
          className={
            skin === Skin.LPIVW
              ? "lincoln-modal-header lpivw-font-family__proxima-nova"
              : "modal-header"
          }
          data-testid="warning-message-header">
          <TextTranslation textId={"warningMessageHeader"} />
        </h3>
      </>
    );
  }

  function getForm() {
    return (
      <div
        data-brand={skin === Skin.LPIVW ? "lincoln" : ""}
        className="warning-modal-container">
        {getHeader()}

        <label
          data-testid={"warning-description"}
          id={"warning-description"}
          className={
            skin === Skin.LPIVW
              ? "modal-description lincoln-warning-modal-description lpivw-font-family__proxima-nova"
              : "fds-color__text--error1 modal-description"
          }>
          <TextTranslation textId={"warningMessageText"} />
        </label>
        <textarea
          className={
            skin === Skin.LPIVW
              ? "modal-text lpivw-font-family__proxima-nova"
              : "modal-text"
          }
          rows={7}
          aria-describedby={"warning-description"}
          placeholder={translations["warningMessagePlaceholder"]}
          onChange={(event) => {
            setWarningMessage(event.target.value);
          }}></textarea>
        <div className="fmc-mt-6 center-content">
          <button
            disabled={warningMessage === ""}
            className={"fmc-button fmc-mb-3"}
            aria-label={"Submit warning modal"}
            onClick={() => {
              getHttpClient()
                .post(ApiUrls.warningMessage, {message: warningMessage})
                .then(() => {
                  setIsSubmitted(true);
                });
            }}>
            <TextTranslation textId={"warningMessageButton"} />
          </button>
        </div>
      </div>
    );
  }

  function getSuccessMessage() {
    return (
      <div
        data-brand={skin === Skin.LPIVW ? "lincoln" : ""}
        className="warning-modal-container">
        {getHeader()}
        <div className={"warning-success"}>
          <p>
            <TextTranslation textId={"urgentMessageSuccess"} />
          </p>
          <p>
            <TextTranslation textId={"refreshPage"} />
          </p>
        </div>
        <div className="fmc-mt-6 center-content">
          <button
            className={"fmc-button fmc-mb-3"}
            aria-label={"Close warning modal"}
            onClick={() => {
              setWarningModalIsOpen(false);
            }}>
            <TextTranslation textId={"closeButtonLabel"} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <Modal
      isOpen={warningModalIsOpen}
      ariaHideApp={false}
      onRequestClose={() => {
        setWarningModalIsOpen(false);
      }}>
      {!isSubmitted && getForm()}
      {isSubmitted && getSuccessMessage()}
    </Modal>
  );
}
