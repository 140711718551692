import ReactTooltip from "react-tooltip";
import lincolnTooltip from "../../images/lincolnTooltip.svg";
import "../../styles/RebillingTooltip.scss";

import {TextTranslation} from "../../utils/Translations";

export function LincolnRebillingTooltip() {
  return (
    <>
      <div className={"tool-tip"} data-testid={"vehicle-option-tooltip"}>
        <ReactTooltip
          role={"rebilling-tooltip"}
          place={"bottom"}
          effect={"solid"}
          multiline={true}
          id={"rebilling-tooltip"}
          class={"react-tooltip"}>
          <TextTranslation textId={"tooltip"} />
        </ReactTooltip>
        <span
          className={"tool-tip"}
          data-testid={"tool-tip-span"}
          data-tip={"data-tip-overidden-content"}
          data-for={"rebilling-tooltip"}>
          <img
            className={"tool-tip-img"}
            src={lincolnTooltip}
            alt={"Tooltip"}
          />
        </span>
      </div>
    </>
  );
}
