import {useNavigate} from "react-router-dom";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import {getIcon, IconName} from "../../../service/iconFinderService";
import * as React from "react";
import {TextTranslation} from "../../../utils/Translations";

export function AdminMenuButton(props: {show: boolean}) {
  const navigation = useNavigate();

  if (props.show) {
    return (
      <>
        <MenuItem
          data-testid={"admin-menu-button"}
          onClick={() => {
            navigation("/admin");
          }}>
          <ListItemIcon>
            <img
              src={getIcon(IconName.Admin)}
              className="menu-button-icons"
              alt={"Admin menu button"}
            />
          </ListItemIcon>
          <ListItemText>
            <TextTranslation textId={"adminMenuLabel"} />
          </ListItemText>
        </MenuItem>
      </>
    );
  } else return <div></div>;
}
