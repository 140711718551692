import ReactTooltip from "react-tooltip";
import {TextTranslation} from "../../../utils/Translations";

export function createStockingGuideHeader(contentId: string) {
  return (
    <div className={"tool-tip"} data-testid={`${contentId}-tooltip`}>
      <ReactTooltip
        place={"top"}
        effect={"solid"}
        multiline={true}
        id={`${contentId}-tooltip`}
        class={"react-tooltip"}>
        <TextTranslation textId={`${contentId}ToolTip`} />
      </ReactTooltip>
      <span
        className={"tool-tip"}
        data-testid={"tool-tip-span"}
        data-tip={"data-tip"}
        data-for={`${contentId}-tooltip`}
        aria-label={`${contentId}Title`}>
        <p className={"stock-guide-column-header"}>
          <TextTranslation textId={`${contentId}Title`} />
        </p>
      </span>
    </div>
  );
}

export function createMSRPHeader(contentId: string) {
  return (
    <div data-testid={`${contentId}-tooltip`}>
      <ReactTooltip
        place={"top"}
        effect={"solid"}
        multiline={true}
        id={`${contentId}-tooltip`}
        class={"react-tooltip"}>
        <TextTranslation textId={`${contentId}ToolTip`} />
      </ReactTooltip>
      <span
        data-testid={"tool-tip-span"}
        data-tip={"data-tip"}
        data-for={`${contentId}-tooltip`}
        aria-label={`${contentId}Title`}>
        <TextTranslation textId={`${contentId}Title`} />
      </span>
    </div>
  );
}
