import {useGlobalState} from "../../state/GlobalState";
import "../../styles/RebillingTooltip.scss";
import {getAvailableVehicleLines} from "../../service/OfferingService";
import {useEffect, useState} from "react";
import {getSkin, Skin} from "../../service/SkinService";
import Select from "react-select";
import {useTranslation} from "../../utils/Translations";

interface formattedVehicleLine {
  value: string;
  label: string;
}

export function VehicleLineSelector(props: {salesCode: string}) {
  const [vehicleLine, setVehicleLine] = useGlobalState("vehicleLine");
  const salesCode = props.salesCode;
  const [vehicleLineOptions, setVehicleLineOptions] = useState(
    [] as formattedVehicleLine[]
  );
  const translations = useTranslation([
    "selectVehicleLinePlaceholderText",
    "noOptionsSelector",
    "selectVehicleLineWarningMessage",
  ]);

  useEffect(() => {
    getAvailableVehicleLines().then((vehicleLines) => {
      if (getSkin() === Skin.MEW && vehicleLines.length === 1) {
        setVehicleLine(vehicleLines[0]);
      }
      setVehicleLineOptions(formatVehicleLineOptions(vehicleLines));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function formatVehicleLineOptions(vehicleLineOptions: string[]) {
    if (getSkin() === Skin.MEW) {
      return vehicleLineOptions?.map(
        (element: string): formattedVehicleLine => {
          return {
            value: element,
            label: element === "F-150" ? "Lightning" : element,
          };
        }
      );
    } else {
      return vehicleLineOptions?.map(
        (element: string): formattedVehicleLine => {
          return {
            value: element,
            label: element === "2024 MKX-Nautilus" ? "2024 Nautilus" : element,
          };
        }
      );
    }
  }

  const isSalesCodeSelected = () => salesCode !== "";

  const getModelEVehicleLineSelectorPlaceHolder = () => {
    if (vehicleLineOptions.length === 1 && isSalesCodeSelected()) {
      return vehicleLineOptions[0].label;
    }
    return translations["selectVehicleLinePlaceholderText"];
  };

  const getModelEVehicleLineSelectorIsEnabledStatus = () => {
    return isSalesCodeSelected() && vehicleLineOptions.length !== 1;
  };

  return (
    <div
      className={"fmc-top-banner--warning salesCodeContainer"}
      data-testid={"vehicle-line-container"}>
      <div>
        <form data-testid="vls-select" style={{minWidth: "250px"}}>
          <label className="hidden" htmlFor="vehicleLineOptions">
            Vehicle Lines
          </label>
          {getSkin() === Skin.MEW ? (
            <Select
              options={vehicleLineOptions}
              className="sales-code-dropdown"
              name="vehicleLineOptions"
              inputId="vehicleLineOptions"
              id="vehicleLineSelector"
              placeholder={getModelEVehicleLineSelectorPlaceHolder()}
              onChange={(event: any) => setVehicleLine(event.value)}
              isDisabled={!getModelEVehicleLineSelectorIsEnabledStatus()}
              noOptionsMessage={() => translations["noOptionsSelector"]}
            />
          ) : (
            <Select
              className="sales-code-dropdown"
              options={vehicleLineOptions}
              name="vehicleLineOptions"
              inputId="vehicleLineOptions"
              id="vehicleLineSelector"
              placeholder={translations["selectVehicleLinePlaceholderText"]}
              onChange={(event: any) => setVehicleLine(event.value)}
              isDisabled={!isSalesCodeSelected()}
              noOptionsMessage={() => translations["noOptionsSelector"]}
            />
          )}
        </form>
      </div>
      {vehicleLine === "" && (
        <div className={"salesCodeSelectWarning"}>
          <div
            className={
              getSkin() === Skin.LPIVW ? "lincoln-warning-icon" : "warning-icon"
            }>
            <span
              className={
                getSkin() === Skin.LPIVW
                  ? "fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
                  : "fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
              }></span>
            <span>
              &nbsp; {translations["selectVehicleLineWarningMessage"]} &nbsp;
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
