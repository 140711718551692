import React from "react";
import {LincolnRebillingTooltip} from "./LincolnRebillingTooltip";
import {TextTranslation} from "../../utils/Translations";

export function LincolnPortalTitle() {
  return (
    <div className="portal-header">
      <div className={"title-and-tooltip"}>
        <label className="portal-name lincoln-portal-name">
          <TextTranslation textId={"portalTitleText"} />
        </label>
        <LincolnRebillingTooltip />
      </div>
    </div>
  );
}
