import React, {useState} from "react";
import {getSkin, Skin} from "../../service/SkinService";

interface ContactUsInputParams {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputFieldTitle: React.ReactElement;
  missingInputAlertMessageText: React.ReactElement;
  inputFieldText: string;
  className: string;
}

export function ContactUsInput(props: ContactUsInputParams) {
  const [showAlert, setShowAlert] = useState(false);

  function shouldShowAlert() {
    setShowAlert(!props.inputFieldText?.length);
  }

  return (
    <div
      className={`${props.className} contact-us-input-margin`}
      data-testid={"enter-wslx-id"}>
      <label>
        {props.inputFieldTitle}
        <span className={"red-asterisk"}>*</span>
        <input
          className={`contact-us-input-margin ${
            showAlert && "contact-us-alert"
          } contact-us-input-style`}
          type="text"
          onChange={props.onChange}
          onBlur={shouldShowAlert}
        />
      </label>
      {showAlert && (
        <div
          className={
            getSkin() === Skin.LPIVW
              ? "lincoln-contact-us-alert-text"
              : "contact-us-alert-text"
          }>
          <span
            className="fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
            data-testid={`${props.className}-warning-message`}
          />{" "}
          {props.missingInputAlertMessageText}
        </div>
      )}
    </div>
  );
}
