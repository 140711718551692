import ReactTooltip from "react-tooltip";
import {useGlobalState} from "../../state/GlobalState";
import {useEffect, useState} from "react";
import {hasSubmitPermissionsFor} from "../../service/PermissionsService";
import {TextTranslation} from "../../utils/Translations";

export function SubmitButton({
  setModalVisibilityTrue,
}: {
  setModalVisibilityTrue: () => void;
}) {
  const [reallocationPeriodOngoing] = useGlobalState(
    "reallocationPeriodOngoing"
  );
  const [hasReadAllPermissions] = useGlobalState("hasReadAllPermissions");
  const [dealerSelectionList] = useGlobalState("dealerSelectionList");
  const dealerSelectionListIsEmpty = dealerSelectionList.length === 0;
  const [hasSubmitPermissions, setHasSubmitPermissions] = useState(false);
  const [salesCode] = useGlobalState("salesCode");

  useEffect(() => {
    if (salesCode !== "") {
      hasSubmitPermissionsFor(salesCode).then((response) => {
        setHasSubmitPermissions(response.data);
      });
    }
  }, [salesCode]);

  function TooltipMessage() {
    if (!reallocationPeriodOngoing)
      return <TextTranslation textId={"submitButtonTooltipOfferClosed"} />;
    if (!hasSubmitPermissions && salesCode !== "")
      return <TextTranslation textId={"noPermissionForSalesCode"} />;
    return <TextTranslation textId={"submitButtonTooltip"} />;
  }

  function isSubmitButtonDisabled() {
    return (
      dealerSelectionListIsEmpty ||
      !reallocationPeriodOngoing ||
      hasReadAllPermissions ||
      !hasSubmitPermissions
    );
  }

  return (
    <>
      <ReactTooltip
        role={"tooltip"}
        place={"right"}
        effect={"solid"}
        disable={!isSubmitButtonDisabled()}
        id={"submit-tooltip"}>
        <TooltipMessage />
      </ReactTooltip>
      <span
        data-tip={"data-tip-to-override"}
        data-testid={"submit-button-tooltip-text"}
        data-for={"submit-tooltip"}>
        <button
          onClick={setModalVisibilityTrue}
          data-testid={"submit"}
          className={"fmc-button fmc-tooltip__trigger submit-button"}
          disabled={isSubmitButtonDisabled()}
          id={"submit"}>
          {<TextTranslation textId={"approveButtonLabel"} />}
        </button>
      </span>
    </>
  );
}
