import React, {useEffect} from "react";
import "./App.css";
import "./styles/main.scss";
import {
  isAuthenticated,
  isOnLoginOrOAuthCallbackPage,
} from "./service/AuthService";
import {FeatureFlagService} from "./service/FeatureFlagService";
import {getHttpClient} from "./service/API";
import {AxiosStatic} from "axios";
import {setGlobalState} from "./state/GlobalState";
import {isUserAdmin, isUserReadAll} from "./service/PermissionsService";
import {RecoilRoot} from "recoil";
import {getSkin, Skin} from "./service/SkinService";
import {PeriodicOfferingRoutes} from "./routes/PeriodicOfferingRoutes";
import {ModelEOfferingRoutes} from "./routes/ModelEOfferingRoutes";
import {setRedirectURL} from "./components/login/OauthCallback";
import MouseFlow from "./components/mouseflow/Mouseflow";
import {LincolnHeader} from "./components/header/LincolnHeader";
import {ModelEHeader} from "./components/header/ModelEHeader";
import {FordHeader} from "./components/header/FordHeader";
import {useTranslation} from "./utils/Translations";

function setUserAdminPermission(httpClient: AxiosStatic) {
  isUserAdmin().then((response) => {
    setGlobalState("hasAdminPermissions", response.data);
  });
}

function setUserReadAllPermission(httpClient: AxiosStatic) {
  isUserReadAll().then((response) => {
    setGlobalState("hasReadAllPermissions", response.data);
  });
}

function App() {
  useEffect(() => {
    if (isAuthenticated()) {
      FeatureFlagService.setFeatureFlags();
      let httpClient = getHttpClient();
      setUserAdminPermission(httpClient);
      setUserReadAllPermission(httpClient);
    }
  }, []);

  const translation = useTranslation(["title"]);

  if (!isAuthenticated() && !isOnLoginOrOAuthCallbackPage()) {
    setRedirectURL(window.location.href);
    window.location.assign("/login");
    return null;
  }

  document.title = translation["title"];

  const skin = getSkin();

  function getPageTheme(): React.JSX.Element {
    switch (skin) {
      case Skin.CVW:
        return (
          <div className={"App"} data-brand={"ford"}>
            <FordHeader />
            <PeriodicOfferingRoutes />
          </div>
        );

      case Skin.RR:
        return (
          <div className={"App"} data-brand={"ford"}>
            <FordHeader />
            <PeriodicOfferingRoutes />
          </div>
        );
      case Skin.LPIVW:
        return (
          <div
            className={"lpivw-font-family__proxima-nova App"}
            data-brand={"lincoln"}>
            <LincolnHeader />
            <PeriodicOfferingRoutes />
          </div>
        );
      case Skin.MEW:
        return (
          <div className={"App"} data-brand={"ford"}>
            <ModelEHeader />
            <ModelEOfferingRoutes />
          </div>
        );
    }
  }

  return (
    <>
      <MouseFlow />
      <RecoilRoot>{getPageTheme()}</RecoilRoot>
    </>
  );
}

export default App;
