import {RRTooltip} from "../header/RRTooltip";
import React from "react";
import {TextTranslation} from "../../utils/Translations";

export function PortalTitle(props: {showToolTip: boolean}) {
  return (
    <div className="portal-header">
      <div className={"title-and-tooltip"}>
        <h1 className="portal-name">
          <TextTranslation textId={"portalTitleText"} />
        </h1>
        <RRTooltip contentId="tooltip" image={props.showToolTip} />
      </div>
    </div>
  );
}
