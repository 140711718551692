import {
  getFullReportByOfferingId,
  getRebillingReportByOfferingId,
} from "../../service/admin/AdminReportService";
import {CSVLink} from "react-csv";
import React, {LegacyRef, useRef, useState} from "react";
import {AxiosResponse} from "axios";
import {setGlobalState} from "../../state/GlobalState";
import styles from "../../styles/AdminPage.module.scss";
import {TextTranslation} from "../../utils/Translations";

export function AdminPageReports(props: {offeringId: string}) {
  const [reportData, setReportData] = useState([]);
  const [reportFilename, setReportFilename] = useState("");

  const csvRebillingDownloadLink: LegacyRef<any> = useRef();
  const csvFullReportDownloadLink: LegacyRef<any> = useRef();

  const createFileForDownload = (response: AxiosResponse<any>) => {
    setReportData(response.data);
    let responseHeaders = response.headers;
    let fileName = responseHeaders["content-disposition"].split("=")[1];
    fileName = fileName.replaceAll('"', "");
    setReportFilename(fileName);
  };

  const downloadRebillingHandler = async () => {
    await getRebillingReportByOfferingId(props.offeringId)
      .then((response) => {
        createFileForDownload(response);
      })
      .finally(() => setGlobalState("downloadModalVisible", true));
    if (csvRebillingDownloadLink.current)
      csvRebillingDownloadLink.current.link.click();
  };
  const downloadFullReportHandler = async () => {
    await getFullReportByOfferingId(props.offeringId)
      .then((response) => {
        createFileForDownload(response);
      })
      .finally(() => setGlobalState("downloadModalVisible", true));
    if (csvFullReportDownloadLink.current)
      csvFullReportDownloadLink.current.link.click();
  };

  return (
    <>
      <div>
        <div className={"fds-display--inline"}>
          <button
            disabled={props.offeringId === ""}
            className={"fmc-button admin-tool-button " + styles.adminButton}
            onClick={() => downloadFullReportHandler()}>
            <TextTranslation textId={"exportFullReportButton"} />
          </button>
          <CSVLink
            data-testid={"csv-download-Full-report"}
            data={reportData}
            filename={reportFilename}
            ref={csvFullReportDownloadLink}
            target="_blank"
          />
        </div>
        <div className={"fds-display--inline"}>
          <button
            disabled={props.offeringId === ""}
            className={"fmc-button admin-tool-button " + styles.adminButton}
            onClick={() => downloadRebillingHandler()}>
            <TextTranslation textId={"exportRebillingReportButton"} />
          </button>
          <CSVLink
            data-testid={"csv-download-report"}
            data={reportData}
            filename={reportFilename}
            ref={csvRebillingDownloadLink}
            target="_blank"
          />
        </div>
      </div>
    </>
  );
}
