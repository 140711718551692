import {useGlobalState} from "../../state/GlobalState";
import "../../styles/ReallocationGuide.scss";
import {TextTranslation} from "../../utils/Translations";

export function ReallocationGuide() {
  const [offeringWindowOngoing] = useGlobalState("offeringWindowOngoing");
  const [reallocationPeriodOngoing] = useGlobalState(
    "reallocationPeriodOngoing"
  );

  if (!offeringWindowOngoing && reallocationPeriodOngoing) {
    return (
      <div
        data-testid={"reallocation-guide-text"}
        className={"reallocation-guide"}>
        <TextTranslation textId={"ReallocationPeriod"} />
      </div>
    );
  }

  return <></>;
}
