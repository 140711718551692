import "../../../styles/Home.scss";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";
import {getIcon, IconName} from "../../../service/iconFinderService";
import {TextTranslation} from "../../../utils/Translations";

export function ContactUsButton(props: {
  setContactUsVisible: (arg0: boolean) => void;
}) {
  return (
    <>
      <MenuItem
        onClick={() => {
          props.setContactUsVisible(true);
        }}>
        <ListItemIcon>
          <img
            src={getIcon(IconName.ContactUs)}
            className="menu-button-icons"
            alt={"contact us menu button"}
          />
        </ListItemIcon>
        <ListItemText>
          <TextTranslation textId={"contactUsMenuLabel"} />
        </ListItemText>
      </MenuItem>
    </>
  );
}
